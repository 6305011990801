import { CommonModule } from "@angular/common";
import { Component, computed, input, model, output } from "@angular/core";
import { ButtonComponent } from "../button/button.component";
import { RoamIconComponent } from "../../roam-icon/roam-icon.component";
import { MatMenuModule } from "@angular/material/menu";
import { ActionConfigKeys, actionConfig } from "./button-action.config";

/** TEMPLATE
 * Default:
 * <button-action label="More Action" type="view" (onAction)="addNewRequest()"/>
 * <button-action type="view" (onAction)="addNewRequest()"/>
 * type: ActionConfigKeys = 'default' | 'edit' | 'view' | 'add-view' |'upload'  | 'blast'| 'schedule';
 * 
 * Custom:
 * Import Module: ButtonMatActionComponent
 * <button-action [custom]="true">
 *      ....CUSTOM HERE .......
 *      e.g: Custom button list
        @for(act of actions(); track $index){
          <button mat-menu-item>
            <button-mat-action [icon]="act.icon" [label]="act.name"/>
          </button>
        }
    </button-action>
 */
@Component({
  selector: "button-action",
  standalone: true,
  imports: [CommonModule, ButtonComponent, RoamIconComponent, MatMenuModule],
  styles: `
    .red {
      color: red;
      fill: red;
    }
  `,
  template: `
    @if (label() == "contacts") {
      <button
        class="btn btn-o-primary align-center mr-0"
        style="min-width:auto !important;padding:0 1rem !important"
        [matMenuTriggerFor]="menu">
        <roam-icon name="more-horizontal" size="12" color="primary" />
      </button>
    } @else {
      <button
        class="btn btn-o-primary align-center gap-8 mr-0"
        [matMenuTriggerFor]="menu">
        {{ label() }}
        <roam-icon name="chevron-down" size="12" color="primary" />
      </button>
    }

    <mat-menu class="dropdown-content" #menu="matMenu">
      @if (!custom()) {
        @for (act of actions(); track $index) {
          <button mat-menu-item (click)="onAction.emit(act.id)">
            <div class="align-center gap-8">
              <roam-icon
                [name]="act.icon"
                size="18"
                [color]="act.id == 'delete' ? 'warning' : 'black'" />
              <span class="font-14px fw-600" [class.red]="act.id == 'delete'">
                {{ act.name }}
              </span>
            </div>
          </button>
        }
      }
      <ng-content></ng-content>
    </mat-menu>
  `,
})
export class ButtonActionComponent {
  label = input<string>("Actions");
  type = model<ActionConfigKeys>("default");
  onAction = output<string>();
  custom = input<boolean>();

  actions = computed(() => {
    return actionConfig[this.type()];
  });
}
